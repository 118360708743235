<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview>
          <template v-slot:preview>
            <div>
              <b-card
                no-body
                class="full-width"
                style="background-color: rgb(243, 246, 249)"
              >
                <b-row class="mt-5 px-2">
                  <b-col>
                    <b style="font-weight: 600">{{ product.productCode }}</b>
                    -
                    <b style="font-weight: 600">{{ product.productName }}</b>
                  </b-col>
                  <div class="justify-content-end">
                    <b-col>
                      <b class="font-weight-bolder">Tồn kho: {{ product.totalQuantityInStock }}</b>
                    </b-col>
                  </div>
                </b-row>
                <!--begin: Wizard-->
                <div
                  class="wizard wizard-4 mt-5"
                  id="kt_wizard_v4"
                  data-wizard-state="step-first"
                  data-wizard-clickable="true"
                >
                  <!--begin: Wizard Nav-->
                  <div class="wizard-nav">
                    <div class="wizard-steps">
                      <div
                        class="wizard-step"
                        data-wizard-type="step"
                        data-wizard-state="current"
                      >
                        <div class="wizard-wrapper">
                          <div class="wizard-label">
                            <div class="wizard-title">Chi tiết</div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="wizard-step ml-1"
                        data-wizard-type="step"
                      >
                        <div class="wizard-wrapper">
                          <div class="wizard-label">
                            <div class="wizard-title">Danh sách IMEI</div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="wizard-step ml-1"
                        data-wizard-type="step"
                      >
                        <div class="wizard-wrapper">
                          <div class="wizard-label">
                            <div class="wizard-title">Lịch sử</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end: Wizard Nav -->

                  <!--begin: Wizard Body-->
                  <div class="card card-custom card-shadowless rounded-top-0">
                    <div class="card-body p-0">
                      <div class="row py-8 px-8 py-lg-15 px-lg-10">
                        <div class="col-xl-12">
                          <!--begin: Wizard Form-->
                          <form
                            class="form mt-0"
                            id="kt_form"
                          >
                            <!--begin: Chi tiết-->
                            <div
                              class="pb-5 full-width"
                              data-wizard-type="step-content"
                              data-wizard-state="current"
                            >
                              <b-row>
                                <b-col>
                                  <b-form>
                                    <b-form-group
                                      id="input-group-id"
                                      label-for="input-name"
                                    >
                                      <Autosuggest
                                        :model="selectedParent"
                                        :suggestions="filteredOptions"
                                        placeholder="Kho"
                                        @select="onSelected('Store',$event)"
                                        @change="onInputChange('Store',$event)"
                                        suggestionName="suggestionName"
                                      />
                                    </b-form-group>
                                  </b-form>
                                </b-col>
                                <b-col></b-col>
                                <b-col></b-col>
                                <b-col></b-col>
                              </b-row>

                              <b-table
                                :items="detailItems"
                                :fields="detailFields"
                                class="table-bordered table-hover col-md-12"
                                :busy="onLoadingDetail"
                              >
                                <template v-slot:table-busy>
                                  <vcl-table
                                    :speed="5"
                                    :animate="true"
                                    :columns="9"
                                  ></vcl-table>
                                </template>
                                <template v-slot:cell(quantity)="row">
                                  <div class="text-end">
                                    <span v-text="row.item.quantity"></span>
                                  </div>
                                </template>
                                <template v-slot:cell(inStockQuantity)="row">
                                  <div class="text-end">
                                    <span v-text="row.item.inStockQuantity"></span>
                                  </div>
                                </template>
                                <template v-slot:cell(deliveryQuantity)="row">
                                  <div class="text-end">
                                    <span v-text="row.item.deliveryQuantity"></span>
                                  </div>
                                </template>
                                <template v-slot:cell(transferQuantity)="row">
                                  <div class="text-end">
                                    <span v-text="row.item.transferQuantity"></span>
                                  </div>
                                </template>
                                <template v-slot:cell(holdingQuantity)="row">
                                  <div class="text-end">
                                    <span v-text="row.item.holdingQuantity"></span>
                                  </div>
                                </template>
                                <template v-slot:cell(warrantyQuantity)="row">
                                  <div class="text-end">
                                    <span v-text="row.item.warrantyQuantity"></span>
                                  </div>
                                </template>
                                <template v-slot:cell(ctb)="row">
                                  <div class="text-end">
                                    <span v-text="row.item.ctb"></span>
                                  </div>
                                </template>
                                <template v-slot:cell(unitPrice)="row">
                                  <div class="text-end">
                                    <span v-text="convertPrice(row.item.unitPrice)"></span>
                                  </div>
                                </template>
                                <template v-slot:cell(price)="row">
                                  <div class="text-end">
                                    <span v-text="convertPrice(row.item.price)"></span>
                                  </div>
                                </template>
                                <template v-slot:cell(actions)="row">
                                  <div class="d-flex justify-content-center">
                                    <div class="btn-hover-primary">
                                      <v-icon
                                        small
                                        class="mr-2 text-primary icon"
                                        @click="editItem(row.item)"
                                        v-b-tooltip
                                        title="Cập nhật"
                                      >mdi-pencil</v-icon>
                                    </div>
                                  </div>
                                </template>
                              </b-table>
                            </div>
                            <!--end: Chi tiết-->
                            <!--begin: Danh sách IMEI-->
                            <div
                              class="pb-5 full-width"
                              data-wizard-type="step-content"
                              data-wizard-state="current"
                            >
                              <b-row>
                                <b-col>
                                  <b-form>
                                    <b-form-group
                                      id="input-group-id"
                                      label-for="input-name"
                                    >
                                      <Autosuggest
                                        :model="selectedStoreImei"
                                        :suggestions="filteredOptions"
                                        placeholder="Kho"
                                        @select="onSelected('StoreImei',$event)"
                                        @change="onInputChange('StoreImei',$event)"
                                        suggestionName="suggestionName"
                                      />
                                    </b-form-group>
                                  </b-form>
                                </b-col>
                                <b-col>
                                  <b-form>
                                    <b-form-group
                                      id="input-group-emei"
                                      label-for="input-emei"
                                    >
                                      <b-input
                                        v-model="emei"
                                        v-on:input="
                                          getProductInventoryDetail(id)
                                        "
                                        id="input-name"
                                        list="input-emei"
                                        type="text"
                                        size="sm"
                                        placeholder="IMEI"
                                      ></b-input>
                                    </b-form-group>
                                  </b-form>
                                </b-col>
                                <b-col></b-col>
                                <b-col></b-col>
                              </b-row>

                              <b-table
                                :items="productEmeiStocks"
                                :fields="listImeiFields"
                                class="table-bordered table-hover"
                                :busy="onLoadingImei"
                              >
                                <template v-slot:table-busy>
                                  <vcl-table
                                    :speed="5"
                                    :animate="true"
                                    :columns="6"
                                  ></vcl-table>
                                </template>
                                <template v-slot:cell(count)="row">
                                  <div style="text-align: center">
                                    <span v-text="row.item.count"></span>
                                  </div>
                                </template>

                                <template v-slot:cell(storeName)="row">
                                  <div style="text-align: left">
                                    <span v-text="row.item.storeName"></span>
                                  </div>
                                </template>

                                <template v-slot:cell(sellingPrice)="row">
                                  <div style="text-align: end">
                                    <span v-text="
                                        convertPrice(row.item.sellingPrice)
                                      "></span>
                                  </div>
                                </template>

                                <template v-slot:cell(originalPrice)="row">
                                  <div style="text-align: end">
                                    <span v-text="
                                        convertPrice(row.item.originalPrice)
                                      "></span>
                                  </div>
                                </template>

                                <template v-slot:cell(imeiNo)="row">
                                  <div style="text-align: center">
                                    <span v-text="row.item.imeiNo"></span>
                                  </div>
                                </template>

                                <template v-slot:cell(actions)="row">
                                  <div class="d-flex justify-content-center">
                                    <div class="btn-hover-primary">
                                      <v-icon
                                        small
                                        class="mr-2 text-primary icon"
                                        @click="editItem(row.item)"
                                        v-b-tooltip
                                        title="Cập nhật"
                                      >mdi-pencil</v-icon>
                                    </div>
                                  </div>
                                </template>
                              </b-table>
                            </div>
                            <!--end: Danh sách IMEI-->

                            <!--begin: Lịch sử-->
                            <div
                              class="pb-5 full-width"
                              data-wizard-type="step-content"
                            >
                              <b-row>
                                <b-col>
                                  <b-form>
                                    <b-form-group
                                      id="input-group-id"
                                      label-for="input-name"
                                    >
                                      <Autosuggest
                                        :model="selectedStoreHistory"
                                        :suggestions="filteredOptions"
                                        placeholder="Kho"
                                        @select="onSelected('StoreHistory',$event)"
                                        @change="onInputChange('StoreHistory',$event)"
                                        suggestionName="suggestionName"
                                      />
                                    </b-form-group>
                                  </b-form>
                                </b-col>
                                <b-col>
                                  <b-form>
                                    <b-form-group
                                      id="input-group-emei"
                                      label-for="input-emei"
                                    >
                                      <b-form-input
                                        id="input-name"
                                        list="input-emei"
                                        v-model="imei"
                                        v-on:input="getStockHistory"
                                        type="text"
                                        size="sm"
                                        placeholder="IMEI"
                                      ></b-form-input>
                                    </b-form-group>
                                  </b-form>
                                </b-col>
                                <b-col>
                                  <b-form>
                                    <b-form-select
                                      v-on:input="getStockHistory"
                                      v-model="selectedAction"
                                      :options="options"
                                      size="sm"
                                      class="select-style"
                                    ></b-form-select>
                                  </b-form>
                                </b-col>
                                <b-col></b-col>
                              </b-row>

                              <b-table
                                class="table-bordered table-hover"
                                :items="stockHistories"
                                :fields="historyFields"
                                :busy="onLoadingHistory"
                              >
                                <template v-slot:table-busy>
                                  <vcl-table
                                    :speed="5"
                                    :animate="true"
                                    :columns="6"
                                  ></vcl-table>
                                </template>
                                <template v-slot:cell(storeName)="row">
                                  <div style="text-align: left">
                                    <span v-text="row.item.storeName"></span>
                                  </div>
                                </template>
                                <template v-slot:cell(code)="row">
                                  <div
                                    class="productCode"
                                    @click="viewStockSlipDetail(row.item)"
                                    style="cursor: pointer"
                                  >
                                    <span v-text="row.item.code"></span>
                                  </div>
                                </template>
                              </b-table>
                            </div>
                            <!--end: Lịch sử-->
                          </form>
                          <!--end: Wizard Form-->
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end: Wizard Bpdy-->
                </div>
                <!--end: Wizard-->
              </b-card>
            </div>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodeTab.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import KTWizard from '@/assets/js/components/wizard';
import KTUtil from '@/assets/js/components/util';
import { VclTable } from 'vue-content-loading';
import moment from 'moment';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { convertPrice, removeAccents } from '@/utils/common';
import { STOCK_HISTORY_NAME } from '@/utils/enum-name';

export default {
  data() {
    return {
      STOCK_HISTORY_NAME,
      stockHistories: [],
      searchStore: '',
      imei: '',
      id: '',
      product: '',
      productEmeiStocks: [],
      inventoryEmeiDetails: '',
      selectedAction: '',
      options: [
        { value: '', text: 'Chọn hành động' },
        { value: '1', text: 'Nhập Kho' },
        { value: '2', text: 'Xuất kho' },
        { value: '3', text: 'Chuyển kho' },
        { value: '4', text: 'Cập nhật phiếu nhập kho' },
        { value: '5', text: 'Cập nhật phiếu xuất kho' },
        { value: '6', text: 'Xuất chuyển kho' },
        { value: '7', text: 'Nhập chuyển kho' },
        { value: '8', text: 'Xuất kho bán lẻ' },
        { value: '9', text: 'Xuất kho bán buôn' },
        { value: '10', text: 'Nhập từ phiếu trả hàng' },
        { value: '11', text: 'Nhập từ đơn chuyển hoàn' },
        { value: '12', text: 'Xuất kho giao hàng' },
        { value: '13', text: 'Cân kho' },
        { value: '14', text: 'Nhập kho bảo hành' },
        { value: '15', text: 'Cập nhật nhập kho bảo hành' },
        { value: '16', text: 'Xuất kho bảo hành' },
        { value: '17', text: 'Cập nhật xuất kho bảo hành' },
        { value: '18', text: 'Đổi sản phẩm' },
        { value: '19', text: 'Xóa sản phẩm trong hóa đơn' },
        { value: '20', text: 'Xóa hóa đơn' },
        { value: '21', text: 'Xóa hóa đơn trả hàng' },
        { value: '22', text: 'Cập nhật hóa đơn' },
        { value: '23', text: 'Xóa phiếu chuyển kho' },
        { value: '24', text: 'Xóa phiếu nhập' },
        { value: '25', text: 'Xóa phiếu xuất' },
        { value: '59', text: 'Nhập kho mượn máy' },
      ],
      detailFields: [
        {
          thStyle: { textAlign: 'center' },
          key: 'count',
          label: 'STT',
          tdClass: 'sttDetailClass',
          thClass: 'sttDetailClass',
        },
        {
          thStyle: { textAlign: 'center' },
          key: 'store',
          label: 'Kho',
          tdClass: 'storeDetailClass',
          thClass: 'storeDetailClass',
        },
        {
          thStyle: { textAlign: 'center' },
          key: 'quantity',
          label: 'SL',
          tdClass: 'quantityDetailClass',
        },
        {
          thStyle: { textAlign: 'center' },
          key: 'inStockQuantity',
          label: 'Tồn trong kho',
          tdClass: 'quantityDetailClass',
        },
        {
          thStyle: { textAlign: 'center' },
          key: 'deliveryQuantity',
          label: 'Đang giao',
          tdClass: 'quantityDetailClass',
        },
        {
          thStyle: { textAlign: 'center' },
          key: 'transferQuantity',
          label: 'Đang chuyển',
          tdClass: 'quantityDetailClass',
        },
        {
          thStyle: { textAlign: 'center' },
          key: 'holdingQuantity',
          label: 'Đang giữ hàng',
          tdClass: 'quantityDetailClass',
        },
        {
          thStyle: { textAlign: 'center' },
          key: 'warrantyQuantity',
          label: 'Đang bảo hành',
          tdClass: 'quantityDetailClass',
        },
        {
          thStyle: { textAlign: 'center' },
          key: 'ctb',
          label: 'Có thể bán',
          tdClass: 'quantityDetailClass',
        },
        // {
        //   thStyle: { textAlign: 'center' },
        //   key: 'unitPrice',
        //   label: 'Đơn Giá',
        //   tdClass: 'unitPriceDetailClass',
        //   thClass: 'unitPriceDetailClass',
        // },
        // {
        //   thStyle: { textAlign: 'center' },
        //   key: 'price',
        //   label: 'Thành tiền',
        // },
      ],
      listImeiFields: [
        {
          key: 'count',
          label: 'STT',
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '6%',
          },
        },
        {
          key: 'storeName',
          label: 'Kho',
          thStyle: {
            color: '#181c32',
          },
        },
        {
          key: 'imeiNo',
          label: 'IMEI',
          thStyle: { textAlign: 'center', color: '#181c32' },
        },
        {
          key: 'originalPrice',
          label: 'Giá nhập',
          thStyle: { textAlign: 'right', color: '#181c32' },
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán',
          thStyle: { textAlign: 'right', color: '#181c32' },
        },
        {
          key: 'status',
          label: 'Trạng thái',
          thStyle: {
            color: '#181c32',
          },
        },
        { key: 'actions', label: '', color: '#181c32' },
      ],
      historyFields: [
        {
          key: 'count',
          label: 'STT',
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '6%',
          },
        },
        {
          key: 'storeName',
          label: 'Kho',
          thStyle: { textAlign: 'left', color: '#181c32' },
        },
        {
          key: 'imei',
          label: 'IMEI',
          thStyle: { textAlign: 'left', color: '#181c32' },
        },
        {
          key: 'action',
          label: 'Hành động',
          thStyle: { textAlign: 'left', color: '#181c32' },
          tdClass: 'text-left',
        },
        {
          key: 'code',
          label: 'Mã phiếu',
          thStyle: { textAlign: 'left', color: '#181c32' },
          tdClass: 'text-left',
        },
        {
          key: 'createdBy',
          label: 'Người tạo',
          thStyle: { textAlign: 'left', color: '#181c32' },
          tdClass: 'text-left',
        },
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          thStyle: { textAlign: 'left', color: '#181c32' },
          tdClass: 'text-left',
        },
      ],
      detailItems: [],
      historyItems: [],
      filteredOptions: [],
      optionsData: [
        {
          data: [],
        },
      ],
      searchStock: '',
      listStore: [],
      selected: '',
      selectedParent: '',
      selectedStoreHistory: '',
      selectedStoreImei: '',
      onLoadingDetail: false,
      onLoadingImei: false,
      onLoadingHistory: false,
      selectedStore: null,
      selectedStoreImeiId: null,
      selectedStoreHistoryId: null,
    };
  },
  components: {
    KTCodePreview,
    VclTable,
    Autosuggest,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Sản phẩm', route: 'products' },
      { title: 'Danh sách sản phẩm', route: '/products' },
      { title: 'Chi tiết tồn kho SP IMEI ' },
    ]);
    const wizard = new KTWizard('kt_wizard_v4', {
      startStep: 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });

    // Validation before going to next page
    wizard.on('beforeNext', function (/*wizardObj*/) {
      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    });

    // Change event
    wizard.on('change', function (/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  created() {
    this.id = this.$route.query.id;
    this.getProduct(this.id);
    this.getProductInventoryDetail(this.id);
    this.getStockHistory();
    this.fetchStore();
    this.getListProductStock();
  },
  methods: {
    removeAccents,
    convertPrice,
    editItem: function (item) {
      this.$router.push({
        path: 'update-inventory-detail',
        query: { id: item.id },
      });
    },
    onSelected(type, option) {
      switch (type) {
        case 'Store': {
          this.searchStock = option.item.name;
          this.selectedStore = option.item.id;
          this.getListProductStock();
          break;
        }
        case 'StoreImei': {
          this.selectedStoreImeiId = option.item.id;
          this.selectedStoreImei = option.item.name;
          this.getProductInventoryDetail(this.id);
          break;
        }
        case 'StoreHistory': {
          this.selectedStoreHistoryId = option.item.id;
          this.selectedStoreHistory = option.item.name;
          this.getStockHistory();
          break;
        }
        default:
          break;
      }
    },
    onInputChange(type, text) {
      if (!text) {
        text = '';
      }

      switch (type) {
        case 'Store': {
          this.searchStock = text;
          break;
        }
        case 'StoreImei': {
          this.selectedStoreImei = text;
          break;
        }
        case 'StoreHistory': {
          this.selectedStoreHistory = text;
          break;
        }
        default:
          break;
      }

      const filteredData = this.optionsData[0].data.filter((item) => {
        return (
          removeAccents(item.name).toLowerCase().indexOf(text.toLowerCase()) >
            -1 ||
          item.shortName
            .toLowerCase()
            .indexOf(removeAccents(text).toLowerCase()) > -1
        );
      });

      this.filteredOptions = [...filteredData];
    },
    getProductInventoryDetail: async function (productId) {
      let params = {
        imei: this.emei,
        searchStore: this.selectedStoreImeiId,
      };
      this.onLoadingImei = true;
      ApiService.setHeader();
      ApiService.query('productEmeiStock' + '/' + productId, {
        params,
      })
        .then((response) => {
          this.productEmeiStocks = [];
          response.data.data.list_product_emei_stock.forEach((item, index) => {
            let status = '';
            switch (item.status) {
              case 1:
                status = 'Mới';
                break;
              case 2:
                status = 'Đã bán';
                break;
              case 3:
                status = 'Lỗi';
                break;
              case 4:
                status = 'Hàng Demo';
                break;
              case 5:
                status = 'Đã trả nhà cung cấp';
                break;
              case 6:
                status = 'Đang chuyển kho';
                break;
              case 7:
                status = 'Đang bảo hành';
                break;
              default:
                status = '';
            }
            let data = {
              count:
                this.currentPage >= 2
                  ? index + 1 + this.currentPage * 10 - 10
                  : index + 1,
              id: item.id,
              storeName: item.storeName,
              imeiNo: item.imeiNo,
              originalPrice: item.originalPrice,
              sellingPrice: item.sellingPrice,
              status: status,
            };
            this.productEmeiStocks.push(data);
          });
          this.onLoadingImei = false;
        })
        .catch(() => {
          this.onLoadingImei = false;
        });
    },
    getProduct: async function (productId) {
      ApiService.setHeader();
      ApiService.query('productEmeiStock/getProduct/' + productId).then(
        (response) => {
          this.product = response.data.data;
        },
      );
    },
    getStockHistory: async function () {
      let params = {
        productId: this.id,
        searchStore: this.selectedStoreHistoryId,
        imei: this.imei,
        action: this.selectedAction,
      };
      this.onLoadingHistory = true;
      ApiService.setHeader();
      ApiService.query('productEmeiStock', { params })
        .then((response) => {
          this.stockHistories = [];
          response.data.data.list_stock_history.forEach((item, index) => {
            let action = STOCK_HISTORY_NAME[item.action] || '';
            let data = {
              count:
                this.currentPage >= 2
                  ? index + 1 + this.currentPage * 10 - 10
                  : index + 1,
              storeName: item.storeName,
              imei: item.imei,
              action: action,
              code: item.code,
              createdBy: item.createdBy,
              stockSlipId: item.stockSlipId,
              type: item.stockSlipType,
              createdAt: moment(String(item.createdAt)).format(
                'DD/MM/YYYY HH:mm',
              ),
            };
            this.stockHistories.push(data);
          });
          this.onLoadingHistory = false;
        })
        .catch(() => {
          this.onLoadingHistory = false;
        });
    },
    fetchStore: async function () {
      this.optionsData[0].data = [];
      ApiService.setHeader();
      ApiService.get('/stores/getStores').then((response) => {
        const stores = response.data.data;
        this.listStore = stores;
        stores.map((element) => {
          let store = {
            id: element.id,
            name: element.name,
            shortName: element.shortName,
            suggestionName: element.shortName + ' ( ' + element.name + ')',
          };
          this.optionsData[0].data.push(store);
        });
        this.filteredOptions = [...this.optionsData[0].data];
      });
    },
    getListProductStock: function () {
      let param;
      if (this.selectedStore) {
        param = {
          productId: this.id,
          storeId: this.selectedStore,
        };
      } else {
        param = {
          productId: this.id,
        };
      }
      let paramQuery = {
        params: param,
      };
      this.onLoadingDetail = true;
      ApiService.setHeader();
      ApiService.query('productStock', paramQuery)
        .then(({ data }) => {
          if (data.status === 1) {
            this.detailItems = [];
            let count = 1;
            data.data.listStockDetail.forEach((element) => {
              let ctb =
                element.quantity -
                element.holdingQuantity -
                element.warrantyQuantity;
              if (ctb < 0) {
                ctb = 0;
              }
              let item = {
                count: count,
                store: element.storeName,
                quantity:
                  parseInt(element.quantity) !== 0 ? element.quantity : '',
                inStockQuantity:
                  parseInt(element.inStockQuantity) !== 0
                    ? element.inStockQuantity
                    : '',
                deliveryQuantity:
                  parseInt(element.deliveryQuantity) !== 0
                    ? element.deliveryQuantity
                    : '',
                transferQuantity:
                  parseInt(element.transferQuantity) !== 0
                    ? element.transferQuantity
                    : '',
                holdingQuantity:
                  parseInt(element.holdingQuantity) !== 0
                    ? element.holdingQuantity
                    : '',
                warrantyQuantity:
                  parseInt(element.warrantyQuantity) !== 0
                    ? element.warrantyQuantity
                    : '',
                unitPrice: element.unitPrice,
                price: element.quantity * element.unitPrice,
                ctb: ctb !== 0 ? ctb : '',
              };
              this.detailItems.push(item);
              count++;
            });
          } else {
            this.makeToastFaile('Tải dữ liệu thất bại!');
          }
          this.onLoadingDetail = false;
        })
        .catch(({ response }) => {
          this.onLoadingDetail = false;
          this.makeToastFaile(response.$error);
        });
    },
    viewStockSlipDetail: function (item) {
      let path = '';
      if (item.type === 1) {
        path = '/stocks/update-stock';
      } else if (item.type === 2) {
        path = '/export-stocks/update-export-stock';
      } else if (item.type === 3 || item.type === 5) {
        path = '/transfer-stocks/update-transfer-stock';
      }
      this.$router.push({
        path: path,
        query: { id: item.stockSlipId },
      });
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-4.scss';
.full-width .nav-tabs .nav-item {
  margin-bottom: -1px;
  flex-grow: 1 !important;
  text-align: center !important;
}

.table th,
.table td {
  text-align: center;
}

.sttDetailClass {
  width: 3%;
  text-align: center;
}
.storeDetailClass {
  width: 18%;
}
.quantityDetailClass {
  width: 10%;
}
.unitPriceDetailClass {
  width: 14%;
}
.productCode:hover {
  text-decoration: underline;
}
</style>
